<script setup></script>

<template>
    <button
        class="inline-flex gap-2 justify-center items-center font-medium px-4 py-1 text-base shadow rounded-md lowercase"
    >
        <slot />
    </button>
</template>

<style scoped></style>
